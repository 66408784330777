import React, { useState, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"

const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter"

interface SelectProps {
  options: SelectOption[]
  placeholder: string
  onChange: (value: string) => void
  styles?: any
  wrapperStyles?: any
}

const Select: React.FC<SelectProps> = ({
  placeholder,
  options,
  onChange,
  styles,
  wrapperStyles,
}) => {
  const [text, setText] = useState({ placeholder })
  const [selectedName, setSelectedName] = useState("")
  const [isExpanded, setIsExpanded] = useState(false)
  const { language } = useContext(LanguageContext)

  const dropdownRef = useRef()
  useOutsideAlerter(dropdownRef, () => setIsExpanded(false))

  useEffect(() => {
    if (language === "en") {
      setText({ placeholder })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([placeholder])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const onSetSelected = (option: SelectOption) => {
    setSelectedName(option.name)
    setIsExpanded(false)
    if (onChange) {
      onChange(option.value)
    }
  }

  return (
    <div ref={dropdownRef} css={[tw`relative`, wrapperStyles]}>
      <div
        onClick={() => setIsExpanded(val => !val)}
        css={[
          tw`flex cursor-pointer bg-lightGray px-3 py-1.5 rounded-md placeholder-darkGray text-sm h-8 justify-between`,
          isExpanded && tw`z-10`,
          styles,
        ]}
        placeholder={text?.placeholder}
      >
        <p css={[tw`text-darkGray truncate`, selectedName && tw`text-black`]}>
          {selectedName && selectedName.length > 0 ? selectedName : placeholder}
        </p>
        <button aria-expanded={isExpanded}>
          <svg
            css={[tw`transition-transform`, isExpanded && tw`rotate-180`]}
            width="23"
            height="22"
            viewBox="0 0 25 22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(2 1)" fill="none" fillRule="evenodd">
              <circle fill="#385885" cx="10" cy="10" r="10" />
              <path
                stroke="#EAEAEA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m15.75 7.75-5.25 6.5-5.25-6.5"
              />
            </g>
          </svg>
        </button>
      </div>
      <ul
        css={[
          tw`mt-[-3px] overflow-auto max-h-0 transition-all duration-500 ease-in-out absolute bg-white w-full border-lightGray z-2`,
          isExpanded && tw`max-h-72 py-1 border`,
        ]}
      >
        {options?.map((option: SelectOption, index) => (
          <li
            key={index}
            onClick={() => onSetSelected(option)}
            css={[tw`py-2 px-3 cursor-pointer text-xs hover:bg-lightGray`]}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select

export interface SelectOption {
  name: string
  value: string
}
