import React from "react"
import tw, { TwStyle } from "twin.macro"
import Warning from "../Icons/Warning"

type AlertProps = {
  styles?: TwStyle
  wrapperStyles?: TwStyle
  type: "error" | "success"
  message: string
}

const Alert: React.FC<AlertProps> = ({
  message,
  styles,
  wrapperStyles,
  type,
}) => {
  return (
    <div css={[wrapperStyles]}>
      <div
        css={[
          type === "error" ? tw`bg-lightRed` : tw`bg-teal`,
          tw`text-center text-white rounded-lg px-4 py-3 flex flex-row justify-center items-center relative`,
          styles,
        ]}
      >
        {type === "error" && (
          <Warning color="white" css={[tw`h-5 w-5 absolute left-5`]} />
        )}
        {message}
      </div>
    </div>
  )
}

export default Alert
