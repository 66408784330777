import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import tw, { GlobalStyles as TWGlobalStyles } from "twin.macro"
import { navigate } from "gatsby"

import HeaderLogo from "../../components/Icons/HeaderLogo"
import { Alert, Button, Input } from "../../components/Form"
import Close from "../../components/Icons/Close"
import * as API from "../../clients/Tenant"

const TenantPortalPage = () => {
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const authenticated = API.isLoggedIn()
    if (authenticated) {
      navigate("/tenants/")
    }
  }, [])

  const onLogIn = async () => {
    setError("")
    setLoading(true)
    if (!username || !password) {
      setError("Invalid username or password")
      setLoading(false)
      return
    }
    try {
      const result = await API.login(username, password)
      result ? navigate("/tenants/") : setError("Invalid username or password")
    } catch (error) {
      error.response?.status === 401
        ? setError("Invalid username or password")
        : setError("Well this is awkward. Please try again.")
      setLoading(false)
      return
    }
    setLoading(false)
  }

  return (
    <>
      {!API.isLoggedIn() && (
        <>
          <TWGlobalStyles />
          <button
            type="button"
            onClick={() => navigate("/")}
            style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
            tabIndex={0}
            css={[tw`absolute right-6 top-5`]}
          >
            <Close color="fff" iconStyle={tw`w-4 h-4`} />
          </button>
          <div
            css={[
              tw`w-screen flex flex-col items-center ml-auto mr-auto max-w-screen-sm mt-8 md:mt-0`,
            ]}
          >
            <Link
              to="/"
              css={[
                tw`hidden items-center justify-center shadow-thick bg-white w-26 h-24 rounded-b-3xl z-30`,
                tw`md:flex`,
                tw`xl:w-36 xl:h-34`,
              ]}
            >
              <HeaderLogo styling={tw`w-18 h-18 xl:w-24 xl:h-24 m-auto`} />
            </Link>
            <div css={[tw`text-4xl font-extrabold mt-24`]}>Tenant Login</div>
            <div css={[tw`text-lg font-medium mt-5 text-center mx-8`]}>
              Your space for tenant communications, basic tenant information,
              tenant directives and other resources.
            </div>
            <div css={["width: 90%;", tw`flex flex-col mt-3`]}>
              {error && (
                <Alert message={error} type="error" wrapperStyles={tw`mb-2`} />
              )}
              <Input
                wrapperStyles={tw`my-1`}
                styles={[tw`w-full`]}
                placeholder="Username"
                onChange={e => setUsername(e.target.value)}
              />
              <Input
                wrapperStyles={tw`my-1`}
                styles={[tw`w-full`]}
                placeholder="Password"
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <Button
              wrapperStyles={tw`mt-5`}
              text="LOG IN"
              onClick={onLogIn}
              loading={loading}
            />
          </div>
        </>
      )}
    </>
  )
}

export default TenantPortalPage
