import React, { ChangeEvent, TextareaHTMLAttributes } from "react"
import { InputHTMLAttributes } from "react"
import tw from "twin.macro"

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  styles?: any
  wrapperStyles?: any
}

const TextArea: React.FC<TextAreaProps> = ({
  styles,
  wrapperStyles,
  ...props
}) => {
  return (
    <div css={[wrapperStyles]}>
      <textarea
        css={[
          tw`flex bg-lightGray px-3 py-1.5 rounded-md placeholder-darkGray text-sm`,
          styles,
        ]}
        {...props}
      />
    </div>
  )
}

export default TextArea
