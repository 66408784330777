import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"

import { LanguageContext } from "../../context/Language"
import useButtonStates from "../../hooks/useButtonStates"
import TranslateClient from "../../clients/TranslateClient"
import Flights from "../Icons/Flights"
import { StyledButton } from "../Button"

const FormButton: React.FC<{
  styles?: any
  wrapperStyles?: any
  text: string
  loading?: boolean
  disabled?: boolean
  type?: string
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}> = (
  { styles, wrapperStyles, text, onClick, loading, disabled },
  { ...remainingProps }
) => {
  const { hover } = useButtonStates()
  const [buttonText, setButtonText] = useState(text)
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setButtonText(text)
    } else {
      ;(async () => {
        const translations = await TranslateClient.translate(
          [buttonText],
          language
        )
        if (translations) setButtonText(translations[0])
      })()
    }
  }, [language, loading])

  return (
    <div css={[wrapperStyles]}>
      <StyledButton
        primary={true}
        onClick={onClick}
        css={[styles]}
        {...remainingProps}
      >
        {loading ? (
          <div css={[tw`flex justify-center items-center space-x-1`]}>
            <Flights
              iconStyle={tw`w-5 h-5`}
              strokeColor={hover ? "white" : "black"}
              fillColor={hover ? "white" : "black"}
              strokeWidth={0}
            />
            <p css={hover ? tw`text-white` : tw`text-black`}>SENDING...</p>
          </div>
        ) : (
          buttonText
        )}
      </StyledButton>
    </div>
  )
}

export default FormButton
