import React, { useEffect } from "react"

export function useOutsideAlerter(ref: any, onClick: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        onClick &&
        event &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        onClick()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}
