import React, {
  InputHTMLAttributes,
  useState,
  useContext,
  useEffect,
} from "react"
import tw from "twin.macro"

const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  styles?: any
  wrapperStyles?: any
}

const Input: React.FC<InputProps> = ({
  styles,
  wrapperStyles,
  placeholder,
  ...props
}) => {
  const [text, setText] = useState({ placeholder })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ placeholder })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([placeholder])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div css={[wrapperStyles]}>
      <input
        css={[
          tw`flex bg-lightGray px-3 py-1.5 rounded-md placeholder-darkGray text-sm`,
          styles,
        ]}
        placeholder={text?.placeholder}
        {...props}
      />
    </div>
  )
}

export default Input
